<template>
  <div class="space-y-6">
    <div
      v-if="paymentState === 'form'"
      class="bg-white rounded-lg p-4 lg:p-6 shadow-sm"
    >
      <h2 class="text-xl lg:text-2xl font-medium mb-4 lg:mb-6">
        {{ t('print.payment-details') }}
      </h2>

      <div class="space-y-6">
        <div class="mb-4 lg:mb-6">
          <label class="flex items-center gap-2 cursor-pointer">
            <input
              v-model="sameAsShipping"
              type="checkbox"
              class="w-4 h-4 lg:w-5 lg:h-5 rounded border-gray-300 text-primary focus:ring-primary"
            />
            <span class="text-sm lg:text-base">{{
              t('print.same-as-shipping')
            }}</span>
          </label>
        </div>

        <div
          v-if="!sameAsShipping"
          class="bg-[white] rounded-lg p-4 lg:p-6 shadow-sm space-y-4"
        >
          <h3 class="text-lg font-medium">{{ t('print.billing-address') }}</h3>

          <div class="grid gap-4">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label class="block text-sm font-medium text-gray mb-1">
                  {{ t('print.first-name') }} <span class="text-red">*</span>
                </label>
                <input
                  v-model="billingAddress.firstName"
                  type="text"
                  class="w-full p-2 border rounded-lg"
                  :class="
                    !sameAsShipping && !billingAddress.firstName && error
                      ? 'border-red'
                      : 'border-gray'
                  "
                  required
                />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray mb-1">{{
                  t('print.last-name')
                }}</label>
                <input
                  v-model="billingAddress.lastName"
                  type="text"
                  class="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray mb-1">{{
                t('print.address-line-1')
              }}</label>
              <input
                v-model="billingAddress.line1"
                type="text"
                class="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray mb-1">{{
                t('print.address-line-2')
              }}</label>
              <input
                v-model="billingAddress.line2"
                type="text"
                class="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label class="block text-sm font-medium text-gray mb-1">{{
                  t('print.city')
                }}</label>
                <input
                  v-model="billingAddress.city"
                  type="text"
                  class="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray mb-1">{{
                  t('print.state')
                }}</label>
                <input
                  v-model="billingAddress.state"
                  type="text"
                  class="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label class="block text-sm font-medium text-gray mb-1">{{
                  t('print.postal-code')
                }}</label>
                <input
                  v-model="billingAddress.postal_code"
                  type="text"
                  class="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div>
                <label class="block text-sm font-medium text-gray mb-1">{{
                  t('print.country')
                }}</label>
                <input
                  v-model="billingAddress.country"
                  type="text"
                  class="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
                  disabled
                />
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray mb-1">{{
                t('print.phone')
              }}</label>
              <div class="flex gap-2">
                <select
                  v-model="billingAddress.phoneCountryCode"
                  class="w-20 p-2 border border-gray-300 rounded-lg"
                >
                  <option value="+1">+1</option>
                </select>
                <input
                  v-model="billingAddress.phone"
                  type="tel"
                  class="flex-1 p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isBillingAddressValid && !sameAsShipping && error"
          class="text-red text-sm mt-2"
        >
          {{ t('print.please-complete-billing-address') }}
        </div>

        <div id="payment-element" class="mt-4"></div>

        <div v-if="!isFormValid && error" class="text-red text-sm mt-2">
          {{ t('print.please-complete-payment-info') }}
        </div>

        <div v-if="error" class="text-red text-sm mt-2">
          {{ error }}
        </div>

        <div class="flex justify-between pt-4 lg:pt-6">
          <button
            type="button"
            class="px-4 py-2 lg:px-6 text-gray hover:text-gray"
            @click="$emit('back')"
          >
            {{ t('print.back') }}
          </button>
          <button
            type="submit"
            class="px-4 py-2 lg:px-6 bg-primary text-[white] rounded-lg transition-colors"
            :class="
              isSubmitEnabled
                ? 'hover:bg-primary-dark'
                : 'opacity-50 cursor-not-allowed bg-gray'
            "
            :disabled="!isSubmitEnabled || isProcessing"
            @click="handleSubmit"
          >
            <span v-if="isProcessing">{{ t('print.processing') }}...</span>
            <span v-else>{{ t('print.place-order') }}</span>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="paymentState === 'processing'" class="text-center py-12">
      <div
        class="animate-spin rounded-full h-16 w-16 border-b-2 border-primary mx-auto mb-4"
      ></div>
      <h3 class="text-xl font-medium mb-2">
        {{ t('print.processing-payment') }}
      </h3>
      <p class="text-gray">{{ t('print.please-dont-close') }}</p>
    </div>

    <div v-else-if="paymentState === 'success'" class="text-center py-12">
      <div
        class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
      >
        <Icon :icon="CheckSvg" class="w-6 h-6" />
      </div>
      <h3 class="text-xl font-medium mb-2">
        {{ t('print.payment-successful') }}
      </h3>
      <p class="text-gray mb-6">
        {{ t('print.order-confirmation-email') }}
      </p>
      <button
        class="bg-primary text-[white] px-8 py-3 rounded-full hover:bg-[#3b68db] transition-colors"
        @click="goToConfirmation"
      >
        {{ t('print.print-home') }}
      </button>
    </div>

    <div v-else-if="paymentState === 'error'" class="text-center py-12">
      <div
        class="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4"
      >
        <Icon :icon="XMarkSvg" class="w-6 h-6" />
      </div>
      <h3 class="text-xl font-medium mb-2 text-white">
        {{ t('print.payment-failed') }}
      </h3>
      <p class="text-red mb-6">{{ error }}</p>
      <button
        class="px-6 py-2 bg-primary text-[white] rounded-full hover:bg-primary-dark"
        @click="resetPaymentState"
      >
        {{ t('print.try-again') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRuntimeConfig } from '#imports';
import { loadStripe } from '@stripe/stripe-js';
import { usePrintStore } from '~/store/print';
import { apiCreatePrintCheckoutSession } from '~/api/print';
import XMarkSvg from '~/assets/icons/xmark.svg?component';
import CheckSvg from '~/assets/icons/check.svg?component';

const { t } = useI18n();
const config = useRuntimeConfig();
const printStore = usePrintStore();

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'complete'): void;
  (e: 'payment-success'): void;
}>();

const error = ref<string>('');
const isProcessing = ref(false);
const sameAsShipping = ref(true);

const billingAddress = ref({
  firstName: '',
  lastName: '',
  businessName: '',
  phoneCountryCode: '+1',
  phone: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: 'US',
});

const finalBillingAddress = computed(() => {
  if (sameAsShipping.value) {
    const { shippingAddress } = printStore.checkout;
    const [firstName, ...lastNameParts] = shippingAddress.name.split(' ');
    return {
      firstName,
      lastName: lastNameParts.join(' '),
      businessName: shippingAddress.businessName,
      phone: shippingAddress.phone,
      line1: shippingAddress.address.line1,
      line2: shippingAddress.address.line2,
      city: shippingAddress.address.city,
      state: shippingAddress.address.state,
      postal_code: shippingAddress.address.postal_code,
      country: shippingAddress.address.country,
    };
  }
  return billingAddress.value;
});

let stripe: any = null;
let elements: any = null;
let paymentElement: any = null;
let clientSecret: string | null = null;

type PaymentState = 'form' | 'processing' | 'success' | 'error';
const paymentState = ref<PaymentState>('form');

const isFormValid = ref(false);
const isBillingAddressValid = computed(() => {
  if (sameAsShipping.value) return true;

  return (
    billingAddress.value.firstName.trim() !== '' &&
    billingAddress.value.lastName.trim() !== '' &&
    billingAddress.value.line1.trim() !== '' &&
    billingAddress.value.city.trim() !== '' &&
    billingAddress.value.state.trim() !== '' &&
    billingAddress.value.postal_code.trim() !== '' &&
    billingAddress.value.phone.trim() !== ''
  );
});

const initializePaymentForm = async () => {
  try {
    error.value = '';
    paymentState.value = 'form';

    if (paymentElement) {
      paymentElement.unmount();
      paymentElement = null;
    }
    elements = null;

    if (!stripe) {
      stripe = await loadStripe(config.public.stripePublishableKey);
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }
    }

    const requestPayload = {
      items: Object.entries(printStore.selectedItems).map(([key, item]) => {
        const quantity = printStore.quantities[key];
        let price = item.printProduct.basePrice;

        if (item.printProduct.priceTiers?.length) {
          const applicableTier = [...item.printProduct.priceTiers]
            .sort((a, b) => b.quantity - a.quantity)
            .find((tier) => quantity >= tier.quantity);

          if (applicableTier) {
            price = applicableTier.pricePerUnit;
          }
        }

        return {
          contentId: item.id,
          printProductId: item.printProduct.id,
          quantity: printStore.quantities[key],
          price,
          previewUrl: item.previewUrl || item.customPreviewUrl || item.imageUrl,
        };
      }),
      shippingDetails: {
        fullName: printStore.checkout.shippingAddress.name,
        addressLine1: printStore.checkout.shippingAddress.address.line1,
        addressLine2: printStore.checkout.shippingAddress.address.line2,
        city: printStore.checkout.shippingAddress.address.city,
        state: printStore.checkout.shippingAddress.address.state,
        postalCode: printStore.checkout.shippingAddress.address.postal_code,
        country: printStore.checkout.shippingAddress.address.country,
        phone: printStore.checkout.shippingAddress.phone,
        businessName: printStore.checkout.shippingAddress.businessName,
      },
      billingDetails: {
        fullName: sameAsShipping.value
          ? printStore.checkout.shippingAddress.name
          : `${billingAddress.value.firstName} ${billingAddress.value.lastName}`,
        addressLine1: finalBillingAddress.value.line1,
        addressLine2: finalBillingAddress.value.line2,
        city: finalBillingAddress.value.city,
        state: finalBillingAddress.value.state,
        postalCode: finalBillingAddress.value.postal_code,
        country: finalBillingAddress.value.country,
        phone: sameAsShipping.value
          ? printStore.checkout.shippingAddress.phone
          : `${billingAddress.value.phoneCountryCode}${billingAddress.value.phone}`,
      },
      shippingMethod: {
        id: printStore.checkout.shippingRate?.service || '',
        name: printStore.checkout.shippingRate?.service || '',
        price: printStore.checkout.shippingRate?.amount || 0,
        estimatedDays: printStore.checkout.shippingRate?.estimatedDays || '',
      },
      subtotalAmount: printStore.subtotal,
      taxAmount: printStore.checkout.taxAmount,
    };

    ({ clientSecret } = await apiCreatePrintCheckoutSession(requestPayload));

    elements = stripe.elements({
      clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#4374F3',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
          fontFamily: 'system-ui, sans-serif',
          spacingUnit: '4px',
          borderRadius: '4px',
        },
      },
    });

    paymentElement = elements.create('payment', {
      layout: 'tabs',
      paymentMethodOrder: ['card'],
      defaultValues: {
        billingDetails: {
          address: {
            country: 'US',
          },
        },
      },
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
    });

    const mountElement = document.querySelector('#payment-element');
    if (mountElement) {
      paymentElement.mount('#payment-element');
      paymentElement.on('change', (event: any) => {
        error.value = event.error ? event.error.message : '';
        isFormValid.value = event.complete || false;
      });
    } else {
      throw new Error('Payment element mount point not found');
    }
  } catch (err: any) {
    error.value = err.message || t('print.payment-initialization-error');
    paymentState.value = 'error';
  }
};

onMounted(() => {
  nextTick(() => {
    initializePaymentForm().then(() => {
      if (paymentElement) {
        paymentElement.on('change', (event) => {
          error.value = event.error ? event.error.message : '';
          isFormValid.value = event.complete || false;
        });
      }
    });
  });
});

onUnmounted(() => {
  if (paymentElement) {
    paymentElement.unmount();
    paymentElement = null;
  }
  elements = null;
  clientSecret = null;
});

const isSubmitEnabled = computed(() => {
  return (
    isFormValid.value && isBillingAddressValid.value && !isProcessing.value
  );
});

const handleSubmit = async () => {
  if (!stripe || !elements || !paymentElement || !clientSecret) {
    error.value = t('print.payment-not-ready');
    return;
  }

  if (!isFormValid.value) {
    error.value = t('print.please-complete-payment-info');
    return;
  }

  if (!isBillingAddressValid.value) {
    error.value = t('print.please-complete-billing-address');
    return;
  }

  try {
    isProcessing.value = true;
    error.value = '';

    const { error: submitError, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              line1: finalBillingAddress.value.line1,
              line2: finalBillingAddress.value.line2,
              city: finalBillingAddress.value.city,
              state: finalBillingAddress.value.state,
              postal_code: finalBillingAddress.value.postal_code,
              country: finalBillingAddress.value.country,
            },
          },
        },
        return_url: undefined,
      },
      redirect: 'if_required',
    });

    if (submitError) {
      error.value = submitError.message || t('print.payment-failed');
      paymentState.value = 'error';
    } else {
      if (paymentElement) {
        paymentElement.unmount();
        paymentElement = null;
      }
      elements = null;

      paymentState.value = 'success';
    }
  } catch {
    paymentState.value = 'error';
  } finally {
    isProcessing.value = false;
  }
};

const resetPaymentState = async () => {
  paymentState.value = 'form';
  error.value = '';
  await initializePaymentForm();
};

const goToConfirmation = () => {
  printStore.resetAll();
  navigateTo('/print?tab=history');
};

const orderTotals = computed(() => ({
  subtotal: printStore.subtotal,
  salesTax: printStore.subtotal * 0.1,
  shipping: 19,
  total: printStore.subtotal + printStore.subtotal * 0.1 + 19,
}));

watch(sameAsShipping, (newValue) => {
  if (!newValue) {
    billingAddress.value = {
      firstName: '',
      lastName: '',
      businessName: '',
      phoneCountryCode: '+1',
      phone: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    };
  }
});
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:deep(#payment-element) {
  margin-top: 1rem;
}

:deep(.StripeElement) {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
}
</style>
